import type {
  AILatencyMetrics,
  AIQuestionAnswer,
  PartialAIResponse,
} from "party/types";
import { AiHelpCard } from "./AiHelpCard";
import { useEffect, useState } from "react";
import { AiHelpAsk } from "./AiHelpAsk";

export type AiHelp = PartialAIResponse | AIQuestionAnswer;

export interface AiHelpBrowserProps {
  help: AiHelp | null;
  history?: AiHelp[];
  latencyMetrics?: AILatencyMetrics;
  handleGetHelp: (question: string) => void;
}

export function AiHelpBrowser({
  help,
  history = [],
  latencyMetrics,
  handleGetHelp,
}: Readonly<AiHelpBrowserProps>) {
  const [liveHelp, setLiveHelp] = useState<AiHelp | null>(help);
  const [currentHelp, setCurrentHelp] = useState<AiHelp | null>(help);
  const [currentIndex, setCurrentIndex] = useState(history.length - 1);

  useEffect(() => {
    if (liveHelp !== help) {
      setCurrentHelp(help);
      setLiveHelp(help);
      setCurrentIndex(history.length - 1);
    }
  }, [help, liveHelp, history.length]);

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
      setCurrentHelp(history[currentIndex - 1]);
    }
  };

  const handleNext = () => {
    if (currentIndex < history.length - 1) {
      setCurrentIndex(currentIndex + 1);
      setCurrentHelp(history[currentIndex + 1]);
    }
  };

  return (
    <div className="flex items-center space-y-4 flex-col">
      <AiHelpAsk onAskQuestion={handleGetHelp} />
      {currentHelp && (
        <AiHelpCard
          help={currentHelp}
          latencyMetrics={latencyMetrics}
          handlePrevious={handlePrevious}
          handleNext={handleNext}
          currentIndex={currentIndex}
          historyLength={history.length}
        />
      )}
    </div>
  );
}
