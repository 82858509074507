import { Card } from "@/components/ui/card";
import { Table, TableBody, TableRow, TableCell } from "@/components/ui/table";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Button } from "@/components/ui/button";
import { FileText, Edit, Loader2 } from "lucide-react";
import { CallScript } from "./types/CallScript";

interface CallScriptListProps {
  callScripts: CallScript[];
  isLoading: boolean;
  isError: boolean;
  onEdit: (id: string) => void;
}

export function CallScriptList({
  callScripts,
  isLoading,
  isError,
  onEdit,
}: CallScriptListProps) {
  return (
    <div className="flex flex-col h-full">
      <Card className="flex-grow overflow-hidden">
        <ScrollArea className="h-full">
          <div>
            {isLoading ? (
              <div className="flex justify-center items-center h-full">
                <Loader2 className="h-8 w-8 animate-spin" />
              </div>
            ) : isError ? (
              <div className="flex justify-center items-center h-full text-red-500">
                Error loading call scripts. Please try again.
              </div>
            ) : callScripts.length === 0 ? (
              <div className="flex justify-center items-center h-full text-gray-500 text-sm">
                <div className="py-8">Create your first call script</div>
              </div>
            ) : (
              <Table>
                <TableBody>
                  {callScripts.map((script) => (
                    <TableRow key={script.id}>
                      <TableCell className="w-1/2">
                        <FileText className="w-4 h-4 mr-2 inline-block -mt-1" />
                        {script.title}
                      </TableCell>
                      <TableCell className="w-1/3 text-right text-gray-500 text-xs">
                        Created: {new Date(script.createdAt).toLocaleString()}
                      </TableCell>
                      <TableCell className="text-right w-1/6">
                        <Button
                          variant="secondary"
                          onClick={() => onEdit(script.id)}
                        >
                          <Edit size={16} className="mr-2" />
                          Edit
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            )}
          </div>
        </ScrollArea>
      </Card>
    </div>
  );
}
