import { useState, useEffect } from "react";
import { Button } from "@/components/ui/button";
import { X } from "lucide-react";
import yaml from "js-yaml";
import {
  CallScript,
  CallScriptSection,
  CallScriptSectionSchema,
} from "./types/CallScript";
import CodeMirror from "@uiw/react-codemirror";
import { yaml as yamlLang } from "@codemirror/lang-yaml";
import { solarizedLight } from "@uiw/codemirror-theme-solarized";

interface CallScriptEditorProps {
  script: CallScript;
  onSave: (script: CallScript) => void;
  onClose: () => void;
}

export function CallScriptEditor({
  script,
  onSave,
  onClose,
}: CallScriptEditorProps) {
  const [editedSections, setEditedSections] = useState("");
  const [validationError, setValidationError] = useState<string | null>(null);

  useEffect(() => {
    const yamlContent = yaml.dump(script.sections || [], {
      lineWidth: -1,
      noRefs: true,
      quotingType: '"',
    });
    setEditedSections(yamlContent);
  }, [script]);

  const validateYaml = (yamlString: string): boolean => {
    try {
      const parsedYaml = yaml.load(yamlString) as CallScriptSection[];
      CallScriptSectionSchema.array().parse(parsedYaml);
      setValidationError(null);
      return true;
    } catch (error) {
      if (error instanceof Error) {
        setValidationError(error.message);
      } else {
        setValidationError("An unknown error occurred");
      }
      return false;
    }
  };

  const handleSave = () => {
    if (validateYaml(editedSections)) {
      try {
        const parsedSections = yaml.load(editedSections) as CallScriptSection[];
        const updatedScript: CallScript = {
          ...script,
          sections: parsedSections,
          updatedAt: new Date().toISOString(),
        };
        onSave(updatedScript);
      } catch (error) {
        console.error("Error parsing YAML:", error);
        setValidationError(
          "Error parsing script content. Please check your input."
        );
      }
    }
  };

  return (
    <div className="flex flex-col h-full">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-medium">
          Edit Call Script: {script.title}
        </h2>
        <Button variant="ghost" onClick={onClose}>
          <X size={24} />
        </Button>
      </div>
      <p className="text-sm text-gray-500 mb-2">
        Edit the script sections below. Each section should have a title and a
        list of items.
      </p>
      <CodeMirror
        value={editedSections}
        height="400px"
        extensions={[yamlLang()]}
        theme={solarizedLight}
        onChange={(value) => {
          setEditedSections(value);
          validateYaml(value);
        }}
        className="mb-4"
      />
      {validationError && (
        <div className="text-red-500 mb-4">
          {validationError.split("\n").map((error, index) => (
            <p key={index}>{error}</p>
          ))}
        </div>
      )}
      <div>
        <Button onClick={handleSave} disabled={!!validationError}>
          Save
        </Button>
      </div>
    </div>
  );
}
