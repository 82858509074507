import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Loader2, Sparkles, X } from "lucide-react";
import {
  Popover,
  PopoverTrigger,
  PopoverContent,
} from "@radix-ui/react-popover";
import { CallServiceIcon } from "./CallServiceIcon";
import type {
  TranscriptUpdate,
  RecallBot,
  AIQuestionAnswer,
  PartialAIResponse,
  AILatencyMetrics,
  PlaybookProgress,
  PlaybookScriptItem,
} from "party/types";
import { CallStatus } from "./CallStatus";
import { PlaybookDisplay } from "./PlaybookDisplay";
import { useState } from "react";
import { CallModeMenu } from "./CallModeMenu";
import { ErrorNotice } from "./ErrorNotice";
import { TranscriptTable } from "./TranscriptTable";
import { AiHelpBrowser } from "./AiHelpBrowser";
import { LoadingSpinner } from "./LoadingSpinner";
import { PlaybookScriptDisplay } from "./PlaybookScriptDisplay";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { PartyKitServerConnectionStatus } from "./useCallDisplay";

export interface CallDisplayUIProps {
  transcript: TranscriptUpdate[];
  roomBot: RecallBot | null;
  aiHistory: AIQuestionAnswer[];
  host: string;
  error: string | null;
  clearError?: () => void;
  streamingResponse: PartialAIResponse | null;
  isStreaming: boolean;
  latencyMetrics?: AILatencyMetrics;
  playbook: PlaybookProgress;
  currentScriptItem?: PlaybookScriptItem;
  handleGetHelp: (question?: string) => void;
  handleSimulateConversation: () => void;
  handleSimulateFullScript: () => void;
  handleSimulateCurrentScriptItem: () => void;
  handleRequestBotInfo: () => void;
  handleReset: () => void;
  leaveCall: () => void;
  partyKitServerConnectionStatus: PartyKitServerConnectionStatus;
}

const TopBar = ({
  roomBot,
  isStreaming,
  handleGetHelp,
  leaveCall,
  activeMode,
  setActiveMode,
}: Readonly<{
  roomBot: RecallBot | undefined;
  isStreaming: boolean;
  handleGetHelp: (question?: string) => void;
  leaveCall: () => void;
  activeMode: "playbook" | "aihelp" | "script";
  setActiveMode: (mode: "playbook" | "aihelp" | "script") => void;
}>) => (
  <div>
    <div className="grid grid-cols-3 items-center p-2 border-b border-gray-300 mb-4">
      <div className="justify-self-start">
        <Button
          variant="default"
          onClick={() => {
            handleGetHelp();
            setActiveMode("aihelp");
          }}
          disabled={isStreaming}
          className="w-full"
        >
          <Sparkles size={16} className="mr-2" />
          {isStreaming ? "Getting AI Help..." : "Get AI Help"}
        </Button>
      </div>
      <div className="justify-self-center">
        {roomBot?.meeting_url ? (
          <CallServiceIcon
            url={
              typeof roomBot.meeting_url === "string"
                ? roomBot.meeting_url
                : `https://${roomBot.meeting_url.platform}.com/${roomBot.meeting_url.meeting_id}`
            }
          />
        ) : (
          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
        )}
      </div>
      <div className="justify-self-end">
        <Button variant="secondary" onClick={leaveCall} className="w-full">
          <X size={16} />
        </Button>
      </div>
    </div>

    <div className="flex justify-center">
      <CallModeMenu activeMode={activeMode} setActiveMode={setActiveMode} />
    </div>
  </div>
);

const MainContent = ({
  error,
  clearError,
  streamingResponse,
  latencyMetrics,
  aiHistory,
  transcript,
  handleGetHelp,
  activeMode,
  playbook,
  currentScriptItem,
}: Readonly<{
  error: string | null;
  clearError?: () => void;
  streamingResponse: PartialAIResponse | null;
  latencyMetrics?: AILatencyMetrics;
  aiHistory: AIQuestionAnswer[];
  transcript: TranscriptUpdate[];
  handleGetHelp: () => void;
  activeMode: "playbook" | "aihelp" | "script";
  playbook: PlaybookProgress;
  currentScriptItem?: PlaybookScriptItem;
}>) => {
  const aiHelp =
    streamingResponse ||
    (aiHistory.length > 0 ? aiHistory[aiHistory.length - 1] : null);
  return (
    <div className="flex-grow overflow-y-auto p-4">
      <div>
        {error && (
          <div className="mb-2">
            <ErrorNotice error={error} clearError={clearError} />
          </div>
        )}

        {activeMode === "script" && (
          <PlaybookScriptDisplay
            currentScriptItem={currentScriptItem}
            playbook={playbook}
          />
        )}
        {activeMode === "playbook" && <PlaybookDisplay playbook={playbook} />}
        {activeMode === "aihelp" && (
          <>
            <div className="mb-4">
              <AiHelpBrowser
                help={aiHelp}
                history={aiHistory}
                latencyMetrics={latencyMetrics}
                handleGetHelp={handleGetHelp}
              />
            </div>

            {/* {aiHistory.length > 0 && (
              <div>
                <h3>Past Help</h3>
                {aiHistory
                  .slice()
                  .reverse()
                  .map((item, index) => (
                    <div key={index}>
                      <p>
                        <strong>Question:</strong> {item.question}
                      </p>
                      <p>
                        <strong>Answer:</strong> {item.answer}
                      </p>
                      <p>
                        <strong>Context:</strong> {item.context}
                      </p>
                      <p>
                        <small>
                          Time: {new Date(item.timestamp).toLocaleString()}
                        </small>
                      </p>
                    </div>
                  ))}
              </div>
            )} */}
            <div>
              <Card className="mb-4">
                <ScrollArea className="w-full h-[320px]">
                  <TranscriptTable
                    transcript={transcript}
                    handleGetHelp={handleGetHelp}
                  />
                </ScrollArea>
              </Card>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const Footer = ({
  handleSimulateConversation = () => {},
  handleSimulateFullScript = () => {},
  handleSimulateCurrentScriptItem = () => {},
  handleRequestBotInfo = () => {},
  handleReset = () => {},
  roomBot,
  partyKitServerConnectionStatus,
}: Readonly<{
  handleSimulateConversation: () => void;
  handleSimulateFullScript: () => void;
  handleSimulateCurrentScriptItem: () => void;
  handleRequestBotInfo: () => void;
  handleReset: () => void;
  roomBot: RecallBot | undefined;
  partyKitServerConnectionStatus: PartyKitServerConnectionStatus;
}>) => {
  const status = roomBot?.status_changes?.[
    roomBot.status_changes.length - 1
  ] ?? { code: "starting_up" };

  return (
    <div className="flex justify-between items-center p-2 border-t border-gray-300">
      <div>
        <CallStatus
          status={status?.code}
          handleRequestBotInfo={handleRequestBotInfo}
          partyKitServerConnectionStatus={partyKitServerConnectionStatus}
        />
      </div>
      <div className="grid grid-cols-2 gap-2">
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <Button size="sm" variant="outline">
              Simulate
            </Button>
          </DropdownMenuTrigger>
          <DropdownMenuContent>
            <DropdownMenuItem onClick={handleSimulateConversation}>
              Simulate conversation
            </DropdownMenuItem>
            <DropdownMenuItem onClick={handleSimulateCurrentScriptItem}>
              Simulate current script item
            </DropdownMenuItem>
            <DropdownMenuItem onClick={handleSimulateFullScript}>
              Simulate full script
            </DropdownMenuItem>
            <DropdownMenuItem onClick={handleReset}>Reset</DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
        <Popover>
          <PopoverTrigger asChild>
            <Button variant="outline" size="sm">
              Debug
            </Button>
          </PopoverTrigger>
          <PopoverContent>
            <div className="p-4">
              <Card className="w-80 p-4 max-h-[80vh] overflow-y-auto">
                <ScrollArea className="w-full">
                  <pre className="text-sm word-wrap overflow-x-scroll w-full">
                    {JSON.stringify(roomBot, null, 2)}
                  </pre>
                </ScrollArea>
              </Card>
            </div>
          </PopoverContent>
        </Popover>
      </div>
    </div>
  );
};

export function CallDisplayUI({
  transcript,
  roomBot,
  aiHistory,
  error,
  clearError,
  streamingResponse,
  isStreaming,
  latencyMetrics,
  handleGetHelp,
  handleSimulateConversation = () => {},
  handleSimulateFullScript = () => {},
  handleSimulateCurrentScriptItem = () => {},
  handleRequestBotInfo = () => {},
  handleReset = () => {},
  leaveCall,
  playbook,
  currentScriptItem,
  partyKitServerConnectionStatus,
}: Readonly<CallDisplayUIProps>) {
  const [activeMode, setActiveMode] = useState<
    "playbook" | "aihelp" | "script"
  >("script");
  // if (!roomBot) {
  //   return (
  //     <>
  //       No bot
  //       <LoadingSpinner reason={"no room bot"} />
  //     </>
  //   );
  // }

  return (
    <div className="flex flex-col h-full">
      <TopBar
        roomBot={roomBot ?? undefined}
        isStreaming={isStreaming}
        handleGetHelp={handleGetHelp}
        leaveCall={leaveCall}
        activeMode={activeMode}
        setActiveMode={setActiveMode}
      />
      <MainContent
        error={error}
        clearError={clearError}
        streamingResponse={streamingResponse}
        latencyMetrics={latencyMetrics}
        aiHistory={aiHistory}
        transcript={transcript}
        handleGetHelp={handleGetHelp}
        activeMode={activeMode}
        playbook={playbook}
        currentScriptItem={currentScriptItem}
      />
      <Footer
        handleSimulateConversation={handleSimulateConversation}
        handleSimulateFullScript={handleSimulateFullScript}
        handleSimulateCurrentScriptItem={handleSimulateCurrentScriptItem}
        handleRequestBotInfo={handleRequestBotInfo}
        handleReset={handleReset}
        roomBot={roomBot ?? undefined}
        partyKitServerConnectionStatus={partyKitServerConnectionStatus}
      />
    </div>
  );
}
