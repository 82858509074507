import { useCallDisplay } from "./useCallDisplay";
import { CallDisplayUI } from "./CallDisplayUI";

export function CallDisplay({
  host,
  roomId,
  leaveCall,
}: Readonly<{ roomId: string; leaveCall: () => void; host: string }>) {
  const callDisplayProps = useCallDisplay(roomId, host);

  return (
    <CallDisplayUI {...callDisplayProps} leaveCall={leaveCall} host={host} />
  );
}
