import {
  useOrganization,
  OrganizationSwitcher,
  UserButton,
} from "@clerk/clerk-react";

export function OrganizationCheck({
  children,
}: Readonly<{ children: React.ReactNode }>) {
  const { organization } = useOrganization();

  if (!organization) {
    return (
      <>
        <div className="flex justify-between items-center w-full mb-4">
          <div>
            <OrganizationSwitcher />
          </div>
          <div className="text-right">
            <UserButton />
          </div>
        </div>
        <div>
          <h2>Create or Select an Organisation</h2>
          <p>You need to create or select an organisation to get started.</p>
          <OrganizationSwitcher />
        </div>
      </>
    );
  }

  return <>{children}</>;
}
