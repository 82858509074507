import { Trash2 } from "lucide-react";
import { CallServiceIcon } from "../CallServiceIcon";
import { ScrollArea } from "@/components/ui/scroll-area";
import { Card } from "@/components/ui/card";
import { Table, TableBody, TableRow, TableCell } from "@/components/ui/table";
import { Button } from "@/components/ui/button";

export const CallList = ({
  deleteCall,
  selectCall,
  calls,
}: {
  calls: { id: string; url: string; createdAt: string }[];
  deleteCall: (id: string) => void;
  selectCall: (id: string) => void;
}) => {
  return (
    <Card className="flex-grow flex flex-col overflow-hidden">
      <ScrollArea className="flex-grow">
        <Table>
          <TableBody>
            {calls.map((call) => (
              <TableRow key={call.id}>
                <TableCell className="p-0 relative w-1/2">
                  <button
                    className="absolute inset-0 w-full h-full text-left p-4 hover:bg-gray-100 transition-colors hover:font-bold"
                    onClick={() => selectCall(call.id)}
                  >
                    <CallServiceIcon url={call.url} />
                  </button>
                </TableCell>
                <TableCell className="w-1/3 text-right text-gray-500 text-xs">
                  {new Date(call.createdAt).toLocaleString()}
                </TableCell>
                <TableCell className="text-right w-1/6">
                  <Button
                    variant="secondary"
                    onClick={() => deleteCall(call.id)}
                  >
                    <Trash2 size={16} />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </ScrollArea>
    </Card>
  );
};
