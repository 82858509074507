import {
  NavigationMenu,
  NavigationMenuItem,
  NavigationMenuList,
  navigationMenuTriggerStyle,
} from "@/components/ui/navigation-menu";
import { NavigationMenuLink } from "@radix-ui/react-navigation-menu";

export interface CallModeMenuProps {
  activeMode: "playbook" | "aihelp" | "script";
  setActiveMode: (mode: "playbook" | "aihelp" | "script") => void;
}
export function CallModeMenu({
  activeMode,
  setActiveMode,
}: Readonly<CallModeMenuProps>) {
  return (
    <NavigationMenu
      value={activeMode}
      onValueChange={(value) => {
        if (["playbook", "aihelp", "script"].includes(value)) {
          setActiveMode(value as "playbook" | "aihelp" | "script");
        }
      }}
    >
      <NavigationMenuList>
        <NavigationMenuItem value="script">
          <NavigationMenuLink
            className={`cursor-pointer ${navigationMenuTriggerStyle()} ${activeMode === "script" ? "font-bold border border-gray-300" : ""}`}
            onClick={() => {
              setActiveMode("script");
            }}
          >
            Script
          </NavigationMenuLink>
        </NavigationMenuItem>
        <NavigationMenuItem value="playbook">
          <NavigationMenuLink
            className={`cursor-pointer ${navigationMenuTriggerStyle()} ${activeMode === "playbook" ? "font-bold border border-gray-300" : ""}`}
            onClick={() => {
              setActiveMode("playbook");
            }}
          >
            Playbook
          </NavigationMenuLink>
        </NavigationMenuItem>
        <NavigationMenuItem value="aihelp">
          <NavigationMenuLink
            className={`cursor-pointer ${navigationMenuTriggerStyle()} ${activeMode === "aihelp" ? "font-bold border border-gray-300" : ""}`}
            onClick={() => {
              setActiveMode("aihelp");
            }}
          >
            AI Help
          </NavigationMenuLink>
        </NavigationMenuItem>
      </NavigationMenuList>
    </NavigationMenu>
  );
}
