import { Card } from "@/components/ui/card";
import { CheckCircleIcon, MessageCircle } from "lucide-react";
import type { PlaybookProgress, PlaybookScriptItem } from "party/types";

export type PlaybookScriptDisplayProps = {
  currentScriptItem?: PlaybookScriptItem;
  playbook: PlaybookProgress;
};
export function PlaybookScriptDisplay({
  playbook,
  currentScriptItem,
}: Readonly<PlaybookScriptDisplayProps>) {
  if (!currentScriptItem) return null;
  const { completed } = currentScriptItem;
  const cardClasses = currentScriptItem?.completed
    ? "border border-green-500 text-green-500 text-xl"
    : "border border-gray-300 text-gray-900 text-xl";
  if (playbook.status === "complete") {
    return (
      <Card className={`p-4 ${cardClasses}`}>
        <div className="flex items-center justify-between">
          <div className="flex items-center gap-4">
            <div className="flex items-center">
              <CheckCircleIcon size={24} />
            </div>
            <div>Call script complete</div>
          </div>
        </div>
      </Card>
    );
  }
  return (
    <Card className={`p-4 ${cardClasses}`}>
      <div className="flex items-center justify-between">
        <div className="flex items-center gap-4">
          <div className="flex items-center">
            {completed && <CheckCircleIcon size={24} />}
            {!completed && <MessageCircle size={24} />}
          </div>
          <div>{currentScriptItem?.text}</div>
        </div>
      </div>
    </Card>
  );
}
