import { Button } from "@/components/ui/button";
import {
  CompressedTable,
  CompressedTableBody,
  CompressedTableCell,
  CompressedTableHead,
  CompressedTableHeader,
  CompressedTableRow,
} from "@/components/ui/table-compressed";
import { Sparkles } from "lucide-react";
import type { TranscriptUpdate } from "party/types";

export interface TranscriptTableProps {
  transcript: TranscriptUpdate[];
  handleGetHelp: (question?: string) => void;
}

export interface Speaker {
  initials: string;
  name: string;
  id: number;
  color: string;
}

export function TranscriptTable({
  transcript,
  handleGetHelp,
}: Readonly<TranscriptTableProps>) {
  const reverseTranscript = transcript.slice().reverse();
  const getSpeakerInitials = (name: string) =>
    name
      .split(" ")
      .map((n) => n[0])
      .join("")
      .toUpperCase();

  const speakers = reverseTranscript.reduce(
    (acc, item) => {
      if (!acc[item.speaker_id]) {
        const initials = getSpeakerInitials(item.speaker);
        const count = Object.values(acc).filter((s) =>
          s.initials.startsWith(initials)
        ).length;
        acc[item.speaker_id] = {
          initials: count > 0 ? `${initials}${count + 1}` : initials,
          name: item.speaker,
          id: item.speaker_id,
          color: `hsl(${(item.speaker_id * 137) % 360}, 70%, 40%)`,
        };
      }
      return acc;
    },
    {} as Record<number, Speaker>
  );
  return (
    <CompressedTable>
      <CompressedTableHeader>
        <CompressedTableRow>
          <CompressedTableHead>Speaker</CompressedTableHead>
          <CompressedTableHead>Transcript</CompressedTableHead>
          <CompressedTableHead className="text-right"></CompressedTableHead>
        </CompressedTableRow>
      </CompressedTableHeader>
      <CompressedTableBody>
        {reverseTranscript.map((update) => (
          <TranscriptTableItem
            speakers={speakers}
            key={update.id}
            item={update}
            handleGetHelp={handleGetHelp}
          />
        ))}
      </CompressedTableBody>
    </CompressedTable>
  );
}

export interface TranscriptTableItemProps {
  item: TranscriptUpdate;
  handleGetHelp: (question?: string) => void;
  speakers: Record<number, Speaker>;
}
export function TranscriptTableItem({
  item,
  handleGetHelp,
  speakers,
}: Readonly<TranscriptTableItemProps>) {
  const words = item.words.map((word) => word.text).join(" ");
  return (
    <CompressedTableRow key={item.id}>
      <CompressedTableCell>
        <span
          className={`inline-block px-2 py-1 rounded-full text-white text-center`}
          style={{ backgroundColor: speakers[item.speaker_id].color }}
        >
          <span className="inline-block min-w-[3ch]">
            {speakers[item.speaker_id].initials}
          </span>
        </span>
      </CompressedTableCell>
      <CompressedTableCell>
        {item.words.map((word) => word.text).join(" ")}
      </CompressedTableCell>
      <CompressedTableCell className="text-right">
        <Button
          variant="ghost"
          size="sm"
          onClick={() => {
            handleGetHelp(words);
          }}
        >
          <Sparkles size={16} />
        </Button>
      </CompressedTableCell>
    </CompressedTableRow>
  );
}
