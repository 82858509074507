import { ClerkProvider, SignIn, SignedIn, SignedOut } from "@clerk/clerk-react";
import { AnalyticsProvider } from "../AnalyticsProvider";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

import { useState } from "react";
import usePartySocket from "partysocket/react";

import { OrganizationCheck } from "./OrganizationCheck";
import { LogOutTracker } from "./LogOutTracker";
import { UserIdentifier } from "./UserIdentifier";
import { CallManager } from "./CallManager";
import { LoadingSpinner } from "./LoadingSpinner";
import { Toaster } from "@/components/ui/toaster";
import NeutralinoUpdater from "../desktop/NeutralinoUpdater";

const queryClient = new QueryClient();

export function App({ partyKitHost }: { partyKitHost: string }) {
  const [clerkPublishableKey, setClerkPublishableKey] = useState<string | null>(
    null
  );
  const [posthogKey, setPosthogKey] = useState<string | null>(null);
  const [posthogEnabled, setPosthogEnabled] = useState<string | null>(null);

  const partySocket = usePartySocket({
    host: partyKitHost,
    room: "waiting-room",
    onMessage(event) {
      const data = JSON.parse(event.data);
      if (data.type === "CLERK_PUBLISHABLE_KEY") {
        setClerkPublishableKey(data.payload);
      } else if (data.type === "POSTHOG_KEY") {
        setPosthogKey(data.payload);
      } else if (data.type === "POSTHOG_ENABLED") {
        setPosthogEnabled(data.payload);
      }
    },
  });

  if (!clerkPublishableKey) {
    return <LoadingSpinner />;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <ClerkProvider publishableKey={clerkPublishableKey}>
        <AnalyticsProvider
          posthogKey={posthogKey}
          posthogEnabled={posthogEnabled}
        >
          <main className="min-h-screen">
            <SignedOut>
              <LogOutTracker />
              <div className="flex items-center justify-center min-h-screen">
                <SignIn />
              </div>
            </SignedOut>
            <SignedIn>
              <UserIdentifier />

              <OrganizationCheck>
                <CallManager host={partyKitHost} partySocket={partySocket} />
              </OrganizationCheck>
              <NeutralinoUpdater />
            </SignedIn>
          </main>
          <Toaster />
        </AnalyticsProvider>
      </ClerkProvider>
    </QueryClientProvider>
  );
}
