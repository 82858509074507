import { useCallManager } from "./useCallManager";
import { CallManagerUI } from "./CallManagerUI";
import { CallManagerWrapper } from "./CallManagerWrapper";
import PartySocket from "partysocket";

export function CallManager({
  host,
  partySocket,
}: {
  host: string;
  partySocket: PartySocket;
}) {
  const callManagerProps = useCallManager(host);

  return (
    <CallManagerWrapper>
      <CallManagerUI {...callManagerProps} partySocket={partySocket} />
    </CallManagerWrapper>
  );
}
